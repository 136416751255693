import { useMemo, cloneElement } from 'react';
import { useTransition, animated } from '@react-spring/web';
import toast, { useToaster } from 'react-hot-toast/headless';
import type { Toast } from 'react-hot-toast';

export function Toaster() {
  const {
    toasts,
    handlers: { startPause, endPause },
  } = useToaster();

  const refMap = useMemo<WeakMap<Toast, HTMLDivElement>>(
    () => new WeakMap(),
    []
  );

  const transitions = useTransition(
    toasts.filter((item) => item.visible).reverse(),
    {
      from: { opacity: 0, height: 0 },
      keys: (item) => item.id,
      enter: (item) => async (next) => {
        await next({
          opacity: 1,
          height: (refMap.get(item)?.offsetHeight ?? 0) + 16,
        });
      },
      leave: [{ opacity: 0 }, { height: 0 }],
    }
  );

  return (
    <div
      className="pointer-events-none fixed top-0 left-0 z-50 flex w-full flex-col items-center justify-start px-4"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {transitions((style, item) => (
        <animated.div
          key={item.id}
          style={style}
          className="pointer-events-auto relative flex max-w-md flex-col items-center justify-end overflow-hidden"
          onClick={() => {
            toast.dismiss(item.id);
          }}
        >
          {cloneElement(item.message as React.ReactElement, {
            ref: (ref: HTMLDivElement) => ref && refMap.set(item, ref),
            ...item.ariaProps,
          })}
        </animated.div>
      ))}
    </div>
  );
}

export default Toaster;
